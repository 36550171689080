import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router'
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NgIf } from '@angular/common';

declare var $: any;

@Component({
    selector: 'fc-pdf',
    templateUrl: './pdf.component.html',
    styleUrls: ['./pdf.component.scss'],
    standalone: true,
    imports: [NgIf]
})
export class PdfComponent implements OnInit {
  pdfToDisplay: SafeResourceUrl | null = null

  constructor(
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer) { }

  ngOnInit() {
    switch (this.route.snapshot.paramMap.get('pdfName')) {
      case "meet-the-team":
        this.pdfToDisplay = this.sanitizer.bypassSecurityTrustResourceUrl('assets/pdfs/Meet_the_team_Jarrett.pdf');
        break;
      case "stretch-dollar":
        this.pdfToDisplay = this.sanitizer.bypassSecurityTrustResourceUrl('assets/pdfs/Stretch_Dollar.pdf');
        break;
      case "faq":
        this.pdfToDisplay = this.sanitizer.bypassSecurityTrustResourceUrl('assets/pdfs/FAQ_Fullpage_01292024.pdf');
        break;
      case "contacts":
        this.pdfToDisplay = this.sanitizer.bypassSecurityTrustResourceUrl('assets/pdfs/MCOE_Team_06242024.pdf');
        break;
      case "usell":
        this.pdfToDisplay = this.sanitizer.bypassSecurityTrustResourceUrl('assets/pdfs/uSell.pdf');
        break;
      case "temporary-order":
        this.pdfToDisplay = this.sanitizer.bypassSecurityTrustResourceUrl('assets/pdfs/2024_Core_Temp_Ordering_Guidelines.pdf');
        break;
      case "esl":
        this.pdfToDisplay = this.sanitizer.bypassSecurityTrustResourceUrl('assets/pdfs/ESL_121719.pdf');
        break;
      case "V2_FSV_Sales_Merchandising_Catalog_Contact":
        this.pdfToDisplay = this.sanitizer.bypassSecurityTrustResourceUrl('assets/pdfs/V2_FSV_Sales_Merchandising_Catalog_Contact.pdf');
        break;
      case "20080_142_FSV_Sales_Merchandising_Catalog_V2_FAQ_JH03":
        this.pdfToDisplay = this.sanitizer.bypassSecurityTrustResourceUrl('assets/pdfs/20080_142_FSV_Sales_Merchandising_Catalog_V2_FAQ_JH03.pdf');
        break;
      case "Merchandising_Catalog_Contact_2021":
        this.pdfToDisplay = this.sanitizer.bypassSecurityTrustResourceUrl('assets/pdfs/Merchandising_Catalog_Contact_2021.pdf');
        break;
      case "21080_067_FSV_Sales_Merchandising_Catalog_FAQ_RB01":
        this.pdfToDisplay = this.sanitizer.bypassSecurityTrustResourceUrl('assets/pdfs/21080_067_FSV_Sales_Merchandising_Catalog_FAQ_RB01.pdf');
        break;
      case "Perm_Merch_Development_Ordering_Guidelines":
        this.pdfToDisplay = this.sanitizer.bypassSecurityTrustResourceUrl('assets/pdfs/Perm_Merch_Development_Ordering_Guidelines.pdf');
        break;
      case "pbna_contact":
        this.pdfToDisplay = this.sanitizer.bypassSecurityTrustResourceUrl('assets/pdfs/pbna_contact.pdf');
        break;
      case "pbna_past_catalogs":
        this.pdfToDisplay = this.sanitizer.bypassSecurityTrustResourceUrl('assets/pdfs/pbna_past_catalogs.pdf');
        break;
      case "pbna_how_to_order":
        this.pdfToDisplay = this.sanitizer.bypassSecurityTrustResourceUrl('assets/pdfs/pbna_how_to_order.pdf');
        break;
      default:
        break;
    }

    setTimeout(() => {
      window.scrollTo(0, 0)
      $('.container.content').scrollTop(0)
    }, 500);
  }
}
